const RedirectErrorMessagesPrefix = 'Failed to launch App by Inter App Linking';

export const RedirectErrorMessages = {
  MISSING_COOKIES: `${RedirectErrorMessagesPrefix}: missing cookies`,
  EXPIRED_TOKEN: `${RedirectErrorMessagesPrefix}: expired token`,
};

export enum AppLinkCookie {
  X_TOKEN = 'x-token',
  X_LINK_ID = 'x-link-id',
}

export const RoutePath = {
  LAUNCH_PAD: '/launch-pad',
  PRODUCT_DEMO: '/product-demo',
  REDIRECT: '/redirect',
  ADMIN: '/super-secret-admin',
  APP_LINK_RETURN: '/app-link-return',
};

export const ServicePath = {
  loginPath: '/login-service/login',
  tokenServicePath: '/token-service',
  userServicePath: '/apiv1/eolsUser/list',
};

import React, {
  Suspense,
  Component
} from 'react';
import {
  Redirect,
  Router,
  Switch
} from 'react-router-dom';
import { createHashHistory } from 'history';
import { ELSModalProvider } from '@els/els-component-modal-react';
import { AppRoutes } from './app.routes';
import '../../assets/main.scss';
import { RoutePath } from './app.constants';
import {
  ELSAccessibilityFocusState,
  ELSCommonUIConstants,
  ELSPageLoader,
  ELSRouterHelper,
  ELSSecurityRoutes,
  ELSTokenServiceRegistrar
} from '../els.components';
import { setDefaultAppConfig } from './app.config';
import { handleAdminLoginCallback } from '../launch-pad/launch-pad.utilities';

const history = createHashHistory();

export default class App extends Component<{}, {}> {

  constructor(props) {
    super(props);
    setDefaultAppConfig();
    ELSTokenServiceRegistrar.initializeFromReload();
  }

  render() {
    return (
      <ELSModalProvider>
        <>
          <Router history={history}>
            <Suspense fallback={<ELSPageLoader />}>
              <Switch>
                {ELSSecurityRoutes.getSecurityRoutes(handleAdminLoginCallback)
                  .concat(AppRoutes.getRoutes())
                  .map(route => ELSRouterHelper.createRoute(route))}
                <Redirect from="/" exact to={`${RoutePath.LAUNCH_PAD}`} />
                <Redirect to={`/${ELSCommonUIConstants.security.States.PageNotFound}`} />
              </Switch>
            </Suspense>
          </Router>
          <ELSAccessibilityFocusState />
        </>
      </ELSModalProvider>
    );
  }
}

import { ELSCommonConfig } from '../components/els.components';

const commonConfig = {
  TokenServiceEnabled: true, // toggle for enabling token service integration
  CORSAllowCredentials: true, // toggle to turn on allow-credentials needed for Amazon S3 Cloud Front Cookies
  EnableLogging: true
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getLowerEnvConfig = (name: string, overrides?: any) => {
  return {
    gatewayBaseURL: `https://eolsapi-${name}.apps.els-ols.com`,
    contentBaseURL: 'https://content-qa.els-ols.com/dev',
    cookiesDomain: '.apps.els-ols.com',
    eolsEnv: '',
    config: commonConfig,
    ...overrides
  };
};

export const ServerConstants = {
  DataSource: ELSCommonConfig.appProfile, // Replace with the preferred source from below.

  dev: getLowerEnvConfig('dev', {
    contentBaseURL: 'https://content-dev.els-ols.com/dev',
  }),
  qa: getLowerEnvConfig('qa'),
  demo: getLowerEnvConfig('demo'),
  test1: getLowerEnvConfig('test1'),
  test2: getLowerEnvConfig('test2'),
  test3: getLowerEnvConfig('test3'),
  test4: getLowerEnvConfig('test4'),
  test5: getLowerEnvConfig('test5'),
  test6: getLowerEnvConfig('test6'),
  test7: getLowerEnvConfig('test7'),
  'ck-cert': getLowerEnvConfig('test7'),
  'cd-staging': getLowerEnvConfig('stage'),
  staging: getLowerEnvConfig('stage'),
  stage: getLowerEnvConfig('stage'),
  stage1: getLowerEnvConfig('stage1'),
  stage2: getLowerEnvConfig('stage2'),
  validate: getLowerEnvConfig('validate', {
    eolsEnv: 'validate',
  }),
  vendor: getLowerEnvConfig('vendor'),
  perf: getLowerEnvConfig('perf', {
    contentBaseURL: 'https://content-perf.els-ols.com/dev',
  }),
  betaprod: getLowerEnvConfig('betaprod', {
    contentBaseURL: 'https://content-betaprod.els-ols.com/dev',
    config: {
      ...ELSCommonConfig,
      PushEventsEnabled: true,
      PushEventsUsesGateway: true,
    }
  }),
  prod: {
    gatewayBaseURL: 'https://eolsapi.elsevier.com',
    contentBaseURL: 'https://eolscontent.elsevier.com',
    cookiesDomain: '.elsevier.com',
    eolsEnv: '',
    config: commonConfig
  }
};

import { ELSFormFieldConfig } from '@els/els-component-form-field-react';
import {
  ServicePath
} from './app.constants';
import { ServerConstants } from '../../constants/server.constants';
import {
  ELSCommonConfig,
  ELSCommonUIConstants
} from '../els.components';

export const setDefaultAppConfig = () => {
  ELSCommonConfig.setBuildUrl(ServerConstants[ServerConstants.DataSource].gatewayBaseURL);
  ELSCommonConfig.setLoginPath(ServicePath.loginPath);
  ELSCommonConfig.setUserServicePath(ServicePath.userServicePath);
  ELSCommonConfig.setTokenServicePath(ServicePath.tokenServicePath);
  ELSCommonConfig.setStorageDriver(ELSCommonUIConstants.security.Locker.SecondaryDriver);
  ELSCommonConfig.setAppId('APP_LINK_TOOL');
  ELSCommonConfig.setUseAppParams(true);
  ELSFormFieldConfig.setI18nConfig({
    dateFormat: {
      primary: 'MM-DD-YYYY'
    }
  });
};

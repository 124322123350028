/* eslint-disable react/react-in-jsx-scope */
import { lazy } from 'react';
import { RoutePath } from './app.constants';
import { ELSCommonUIConstants } from '../els.components';

export const AppRoutes = {
  getRoutes: () => [
    {
      path: RoutePath.PRODUCT_DEMO,
      component: lazy(() => import(/* webpackChunkName: "ProductDemo.component" */ '../product-demo/ProductDemo.component')),
      isPrivate: false,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.genericNav,
          pageName: 'product-demonstration',
        }
      }
    },
    {
      path: RoutePath.ADMIN,
      component: lazy(() => import(/* webpackChunkName: "Admin.component" */ '../admin/Admin.component')),
      isPrivate: false,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.genericNav,
          pageName: 'admin',
        }
      }
    },
    {
      path: RoutePath.LAUNCH_PAD,
      component: lazy(() => import(/* webpackChunkName: "LaunchPad.component" */ '../launch-pad/LaunchPad.component')),
      isPrivate: false,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.genericNav,
          pageName: 'inter-app-redirect',
        }
      }
    },
    {
      path: RoutePath.APP_LINK_RETURN,
      component: lazy(() => import(/* webpackChunkName: "AppLinkReturn.component" */ '../app-link-return/AppLinkReturn.component')),
      isPrivate: false,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.genericNav,
          pageName: 'app-link-return',
        }
      }
    },
    {
      path: RoutePath.REDIRECT,
      component: lazy(() => import(/* webpackChunkName: "Redirect.component" */ '../redirect/Redirect.component')),
      isPrivate: false,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.genericNav,
          pageName: 'inter-app-redirect',
        }
      }
    }
  ]
};

import {
  isNil,
  isNumber,
  map,
  orderBy
} from 'lodash';
import { ELSDropDownOption } from '@els/els-component-form-field-react';
import Cookie from 'js-cookie';
import {
  EvolveProductDto,
  EvolveProductType,
  EvolveProductTypeKey,
  Field,
  primaryEvolveProductTypes
} from './launch-pad.constants';
import {
  ELSCommonConfig,
  ELSURLHelper
} from '../els.components';
import { LaunchPadState } from './LaunchPad.component';
import { ServerConstants } from '../../constants/server.constants';
import { AppLinkCookie } from '../redirect/redirect.constants';
import { ELSCourseSectionDto } from '../../apis/eols-course-crud.dtos';
import { UserDto } from '../../apis/eols-user-crud.dtos';
import { AppConfigMapDto } from '../../apis/eols-app-link.apis';

export const getUrlState = (): Partial<LaunchPadState> => {
  return Object.values(Field).reduce((acc, cur: Field) => {
    let val = ELSURLHelper.getParameterByName(cur);
    if (cur === Field.ISBNS) {
      try {
        val = JSON.parse(val);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        val = {};
      }
    } else if (isNumber(val)) {
      val = val.toString();
    }
    if (!isNil(val)) {
      if ([
        Field.ADVANCED_OPTIONS,
        Field.ENABLE_TOKEN_APP_PARAMS,
        Field.USE_QUERY_PARAMS,
        Field.USE_HASH_LINKING,
        Field.IS_CUSTOM_TOKEN_VIEW,
      ].includes(cur)) {
        val = val === 'true' || val === true;
      }
      acc[cur] = val;
    }
    return acc;
  }, {});
};

export const getSortedCourseSections = (courseSections: ELSCourseSectionDto[]): ELSCourseSectionDto[] => {
  if (!courseSections) {
    return [];
  }
  return orderBy(courseSections, [(courseSection: ELSCourseSectionDto) => {
    return courseSection.id;
  }], ['desc']);
};

export const getCourseSectionOptions = (courseSections: ELSCourseSectionDto[]): ELSDropDownOption[] => {
  return getSortedCourseSections(courseSections).map((courseSection) => {
    return {
      name: `${courseSection.id} | ${courseSection.courseName}`,
      value: courseSection.id.toString()
    };
  });
};

export const getSortedUsers = (users: UserDto[]): UserDto[] => {
  if (!users) {
    return [];
  }
  return orderBy(users, [(user: UserDto) => {
    return user.type;
  }, (user: UserDto) => {
    return user.courseSections.length;
  }], ['asc', 'desc']);
};

export const getUserSelectOptions = (users: UserDto[]): ELSDropDownOption[] => {
  return getSortedUsers(users).map((user) => {
    return {
      name: `${user.id} | ${user.type} | ${user.emailAddress}`,
      value: user.id.toString()
    };
  });
};

export const mapEntitlements = (entitlements) => {
  const defaultOption = {
    name: '',
    value: ''
  };

  if (!entitlements || !entitlements.length) {
    return [defaultOption];
  }

  const entitlementsOptions = entitlements.map(entitlement => {
    return {
      name: `${entitlement.type} ${entitlement.isbn}`,
      value: entitlement.isbn
    };
  });

  return [defaultOption, ...entitlementsOptions];
};

export const getIsbnDropDownForCourse = (courseId, courses) => {
  const currentCourseIndex = courses.map(({ id }) => `${id}`).indexOf(`${courseId}`);
  let entitlements;
  if (currentCourseIndex > -1) {
    entitlements = courses[currentCourseIndex].entitlements;
  }
  return mapEntitlements(entitlements);
};

export const mapAppIds = (appIds) => {
  return [{ name: '', value: '' }, ...map(appIds, appId => ({ name: appId, value: appId }))];
};

const getSingleDefaultISBN = (courseSection: ELSCourseSectionDto): string => {
  const collectionEntitlement = courseSection.entitlements.find((entitlement) => {
    return entitlement.type === EvolveProductType.COLLECTION;
  });
  if (collectionEntitlement) {
    return collectionEntitlement.isbn;
  }
  const validEntitlement = courseSection.entitlements.find((entitlement) => {
    if (!entitlement.data) {
      return false;
    }
    const data = JSON.parse(entitlement.data) as EvolveProductDto;
    if (!data.productTypeKey) {
      return false;
    }
    return primaryEvolveProductTypes.includes(data.productTypeKey);
  });
  if (validEntitlement) {
    return validEntitlement.isbn;
  }
  return null;
};

const getParsedEvolveProducts = (courseSection: ELSCourseSectionDto) => {
  if (!courseSection || !courseSection.entitlements) {
    return [];
  }
  return courseSection.entitlements.reduce((acc, cur) => {
    if (!cur || !cur.data) {
      return acc;
    }
    let data;
    try {
      data = JSON.parse(cur.data) as EvolveProductDto;
    } catch (e) {
      data = null;
    }
    if (!data) {
      return acc;
    }
    return [
      ...acc,
      data
    ];
  }, []);
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const getDefaultIsbns = (courseSection: ELSCourseSectionDto, targetApp: string): Record<string, boolean> => {
  if (!courseSection || !courseSection.entitlements || !courseSection.entitlements.length) {
    return {};
  }

  // Nursing Concepts Online and HESI Learning Service courses do not support mashup courses
  // so only a single isbn should be checked off by default for these courses
  if (['NCO', 'HESI_LS'].includes(targetApp)) {
    const isbn = getSingleDefaultISBN(courseSection);
    if (isbn) {
      return {
        [isbn]: true
      };
    }
  }

  const parsedEntitlements: EvolveProductDto[] = getParsedEvolveProducts(courseSection);

  const selectedIsbnMap = parsedEntitlements.reduce((acc, cur) => {
    if (!cur) {
      return acc;
    }
    return {
      ...acc,
      [cur.isbn]: primaryEvolveProductTypes.includes(cur.productTypeKey)
    };
  }, {});

  parsedEntitlements.filter((entitlement) => {
    return entitlement && entitlement.productTypeKey === EvolveProductTypeKey.SHERPATH_VANTAGE_IA;
  }).forEach((entitlement) => {
    if (!entitlement || !entitlement.realVantageComponent || !entitlement.realVantageComponent.length) {
      return;
    }
    entitlement.realVantageComponent.forEach((component) => {
      if (!component) {
        return;
      }
      if (selectedIsbnMap[component.isbn]) {
        selectedIsbnMap[component.isbn] = false;
      }
    });
  });

  return selectedIsbnMap;
};

export const getDefaultStateOnUserAndCourseSectionSearch = (
  users: UserDto[],
  targetApp: string,
  userId: string,
  courseSectionId: string,
): Partial<LaunchPadState> => {
  if (!users) {
    return {};
  }

  const defaultUser = getSortedUsers(users)[0];
  const foundUser = getSortedUsers(users).find((user) => {
    return user.id.toString() === userId;
  });
  const selectedUser = foundUser || defaultUser;
  const defaultCourse = getSortedCourseSections(selectedUser.courseSections)[0];
  const foundCourse = getSortedCourseSections(selectedUser.courseSections).find((courseSection) => {
    return courseSection.id.toString() === courseSectionId;
  });
  const selectedCourseSection = foundCourse || defaultCourse;
  return {
    userDtoSearchResults: users,
    [Field.USER_ID]: selectedUser.id.toString(),
    [Field.COURSE_SECTION_ID]: selectedCourseSection.id.toString(),
    [Field.ISBNS]: getDefaultIsbns(selectedCourseSection, targetApp)
  };
};

export const getDefaultStateOnUserSearch = (
  users: UserDto[],
  targetApp: string,
): Partial<LaunchPadState> => {
  if (!users) {
    return {};
  }

  const defaultUser = getSortedUsers(users)[0];
  const defaultCourse = getSortedCourseSections(defaultUser.courseSections)[0];
  return {
    userDtoSearchResults: users,
    [Field.USER_ID]: defaultUser.id.toString(),
    [Field.COURSE_SECTION_ID]: defaultCourse.id.toString(),
    [Field.ISBNS]: getDefaultIsbns(defaultCourse, targetApp)
  };
};

export const handleAdminLoginCallback = () => {
  // eslint-disable-next-line no-console
  console.warn('Admin login is deprecated in this tool and will not work.  Please use the Auth0 login flow instead.');
  window.open('/', '_self');
};

export const clearCookies = () => {
  const domain = ServerConstants[ELSCommonConfig.appProfile].cookiesDomain;
  Cookie.remove(AppLinkCookie.X_TOKEN, { path: '/', domain });
  Cookie.remove(AppLinkCookie.X_LINK_ID, { path: '/', domain });
};

export const getTargetAndSourceAppOptions = (appConfigMap: AppConfigMapDto): ELSDropDownOption[] => {
  if (!appConfigMap) {
    return null;
  }

  return Object.keys(appConfigMap)
    .filter((appName: string) => {
      if (ELSCommonConfig.appProfile !== 'prod') {
        return true;
      }
      return appName !== 'HESI_NG';
    })
    .map((app) => {
      const config = appConfigMap[app];
      return {
        name: config && config.displayName ? config.displayName : app,
        value: app
      };
    })
    .sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
};

export enum StorageConstant {
  X_TOKEN = 'x-token',
  X_LINK_ID = 'x-link-id',
  LAUNCH_PAD_FORM_STATE = 'LAUNCH_PAD_FORM_STATE',
  PREVIOUS_URL = 'PREVIOUS_URL',
}

export enum AppLinkRoute {
  LINK_OUT = '/api/app-link/out',
  LINK_RETURN = '/api/app-link/return',
  APPS = '/api/app-link/apps',
  ACTIONS = '/api/app-link/actions'
}

export enum Field {
  USER_SEARCH_STRING = 'USER_SEARCH_STRING',
  USER_ID = 'USER_ID',
  COURSE_SECTION_ID = 'COURSE_SECTION_ID',
  ACTION = 'ACTION',
  CUSTOM_REDIRECT_URL = 'CUSTOM_REDIRECT_URL',
  USE_QUERY_PARAMS = 'USE_QUERY_PARAMS',
  USE_HASH_LINKING = 'USE_HASH_LINKING',
  LINK_OUT_BODY = 'LINK_OUT_BODY',
  ISBNS = 'ISBNS',
  TARGET_APP = 'TARGET_APP',
  SOURCE_APP = 'SOURCE_APP',
  ADVANCED_OPTIONS = 'ADVANCED_OPTIONS',
  ENABLE_TOKEN_APP_PARAMS = 'ENABLE_TOKEN_APP_PARAMS',
  CUSTOM_TOKEN = 'CUSTOM_TOKEN',
  IS_CUSTOM_TOKEN_VIEW = 'IS_CUSTOM_TOKEN_VIEW',
}

export enum TokenRole {
  Adm = 'Adm'
}

export const TOAST_AUTO_CLOSE_TIMEOUT = 4000;

export enum EvolveProductTypeKey {
  SHERPATH_IA = 'sherpath_ia',
  SHERPATH_BOOK_ORGANIZED = 'sherpath_book_organized',
  // Non sellable product used mainly to map EAQs to Sherpath courses
  // This name is confusing since it is used for EAQs but it is meant to be read from the EAQ app perspective
  SHERPATH_COMPONENT_NSS = 'sherpath_component_nss',
  // Non sellable ebook product - not sure why we use this ISBN instead of the vbId here
  SHERPATH_EBOOK_COMPONENT_NSS = 'sherpath_ebook_component_nss',
  SIMULATION_SIM_CHART_NG = 'simulation_sim_chart_ng',
  EAQNG_IA = 'eaqng_ia',
  SHADOW_HEALTH = 'shadow_health',
  HESI_CASE_STUDY = 'case_study',
  HESI_PATIENT_REVIEW = 'patient_reviews',
  HESI_PRACTICE_TEST = 'practice_test',
  NURSING_CONCEPTS_IA = 'nursing_concepts_ia',
  SHERPATH_VANTAGE_IA = 'sherpath_vantage_ia',
}

export const primaryEvolveProductTypes = [
  EvolveProductTypeKey.SHERPATH_IA,
  EvolveProductTypeKey.SHERPATH_BOOK_ORGANIZED,
  EvolveProductTypeKey.SIMULATION_SIM_CHART_NG,
  EvolveProductTypeKey.SHADOW_HEALTH,
  EvolveProductTypeKey.EAQNG_IA,
  EvolveProductTypeKey.HESI_CASE_STUDY,
  EvolveProductTypeKey.HESI_PATIENT_REVIEW,
  EvolveProductTypeKey.HESI_PRACTICE_TEST,
  EvolveProductTypeKey.NURSING_CONCEPTS_IA,
  EvolveProductTypeKey.SHERPATH_VANTAGE_IA,
];

export enum EvolveProductType {
  EBOOK = 'ebook',
  EPRODUCT = 'eproduct',
  COLLECTION = 'COLLECTION'
}

export interface EvolveProductDto {
  bundleMemberProduct: BundleMemberProductDto[]; // Related products mostly used in an upsell type scenario
  components: EvolveProductDto[]; // Bundled ISBNs under this product ISBN
  eeoISBN?: string;
  isbn: string;
  productTypeKey: EvolveProductTypeKey;
  productTypeName: string;
  title: string;
  type: EvolveProductType;
  vbID?: string; // VitalSource ebook ISBN (almost always same as print book ISBN but not always)
  vbId?: string; // There are cases where vbId has a lower case Id
  realVantageComponent?: EvolveProductDto[];
}

export interface BundleMemberProductDto {
  isbn: string;
  productTypeKey: string;
  productTypeName: string;
  title: string;
}

export const AcceptHeader = 'application/json, text/plain, */*';
